import React, { memo, useEffect, useRef } from 'react';
import QRCode from 'qrcode';

interface QrDisplayProps {
  qrCodeData: string;
};


const QR_OPTIONS = {
  margin: 1,
  scale: 5,
  color: {
    dark: '#1b1b1b',
  }
};

const QrDisplay = function ({qrCodeData} : QrDisplayProps) {
  const canvasRef = useRef(null);
  useEffect(() => {
    if(canvasRef.current) {
      QRCode.toCanvas(canvasRef.current, qrCodeData, QR_OPTIONS);
    }
  }, [qrCodeData]);

  return (
    <section className="qr-view">
      <div className="qr-instructions">
        <p>
          To connect VoxSmart to your WhatsApp account:
        </p>
        <ol className="qr-instructions-list">
          <li>Open WhatsApp on your phone</li>
          <li>Tap Menu or Settings and select Linked Devices</li>
          <li>Point your phone to this screen to verify the code</li>
        </ol>
      </div>
      <div className="qr-wrapper">
        <canvas
          ref={canvasRef}
          width={300}
          height={300}
        />
      </div>
    </section>
  );
};

export default memo(QrDisplay);
