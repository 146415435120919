import React, { useState } from 'react';

import { useQrFetcher, useSocketIdleTimeout, useSocketInitialiser, useUuidValidation } from './lib/hooks';
import { States } from './lib/types';
import QrDisplay from './QrDisplay';
import StatefulFeedback from './StatefulFeedback';

import './wham-connect.scss';

const params = new URLSearchParams(window.location.search);
const getUuidFromUrl = (): string => params.get('uuid') || '';

export default function WhamConnectView() {
  const [currentState, setCurrentState] = useState<States>(States.PENDING);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [qr, setQr] = useState('');

  useUuidValidation(getUuidFromUrl(), currentState, setCurrentState);
  useSocketInitialiser(getUuidFromUrl(), currentState, setCurrentState, setErrorMsg);
  useSocketIdleTimeout(getUuidFromUrl(), currentState, setCurrentState);
  useQrFetcher(getUuidFromUrl(), currentState, setCurrentState, setQr, setErrorMsg);
  return (
    <div className="wham-connect">
      <h1>VoxSmart User Authentication: WhatsApp Multidevice</h1>
      {qr
        ? <QrDisplay qrCodeData={qr} />
        : <StatefulFeedback uuid={getUuidFromUrl()} state={currentState} setCurrentState={setCurrentState} errorMsg={errorMsg} />}
    </div>
  );
};
