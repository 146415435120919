export enum States {
  PENDING,
  UUID_INVALID,
  UUID_EXPIRED,
  ALREADY_CAPTURING,
  PENDING_SOCKET_CREATION,
  SOCKET_ACTIVE,
  SOCKET_EXPIRED,
  COMPLETE,
  ERROR,
}
