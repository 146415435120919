import { useEffect } from "react";
import { ApiError, cancelIfUnfinished, fetchQrData, initialiseSocket } from "../../serverApi";
import { errorToast } from "../../Toasts";
import { States } from "./types";

const UUID_REGEX = new RegExp(/^#?([0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})$/i);

function cleanupTimeout (timeout: NodeJS.Timer | undefined) {
  if (timeout) {
    clearTimeout(timeout);
  }
}

function cleanupInterval (interval: NodeJS.Timer | undefined) {
  if (interval) {
    clearInterval(interval);
  }
}

async function extractBestErrorMessage (error: any) {
  if (error instanceof ApiError) {
    let text, json;
    try {
      text = await error.response.text();
      json = JSON.parse(text);
    } catch (e) {};
    const bestMessage = json?.error?.message || text ||error;
    return `API Error: ${bestMessage}`;
  }
  // Order is important, all ApiErrors are of type Error but not all Errors are of type ApiError...
  if (error instanceof Error) {
    return error.message;
  }
  return `API Error: ${error}`;
}

export function useUuidValidation(uuid: string, currentState: States, setCurrentState: Function) {
  useEffect(() => {
    if (currentState === States.PENDING) {
      if (UUID_REGEX.test(uuid)) {
        // State is less clear now -- assume this is active, and fall back to the pending creation if detected no socket
        // rename this state?
        setCurrentState(States.SOCKET_ACTIVE);
      } else {
        setCurrentState(States.UUID_INVALID);
      }
    }
  }, [uuid, currentState, setCurrentState]);
}

export function useSocketInitialiser(uuid: string, currentState: States, setCurrentState: Function, setErrorMsg: Function) {
  useEffect(() => {
    let socketStartupTimeout: undefined | NodeJS.Timer;
    if (currentState === States.PENDING_SOCKET_CREATION) {
      const doSocketInit = async () => {
        try {
          await initialiseSocket(uuid);
        } catch (e) {
          if (e instanceof ApiError) {
            if (e.response.status === 410) {
              return setCurrentState(States.UUID_EXPIRED);
            }
            if (e.response.status === 404) {
              return setCurrentState(States.UUID_INVALID);
            }
            const text = await e.response.text(); // Extract error data via text incase it's not a WhamError object
            let json;
            try {
              json = JSON.parse(text);
            } catch (e) {} // Not interested in an error, json is if/?. checked
            if (json?.error?.errorCode === 'DUPLICATE_SESSION') {
              return setCurrentState(States.ALREADY_CAPTURING);
            }
            errorToast(`API Error: ${json?.error?.message || text}`);
            setErrorMsg(json?.error?.message || text || e.message);
          } else {
            errorToast(`Unknown Error ${(e as Error).message}`);
            setErrorMsg((e as Error).message);
          }
          return setCurrentState(States.ERROR);
        }
        socketStartupTimeout = setTimeout(() => setCurrentState(States.SOCKET_ACTIVE), 2000);
      };
      doSocketInit();
    }
    return () => {
      if (socketStartupTimeout) {
        clearTimeout(socketStartupTimeout);
      }
    };
  }, [uuid, currentState, setCurrentState, setErrorMsg]);
};

export function useSocketIdleTimeout(uuid: string, currentState: States, setCurrentState: Function) {
  useEffect(() => {
    let idleTimeout: NodeJS.Timer | undefined;
    if (currentState === States.SOCKET_ACTIVE) {
      idleTimeout = setTimeout(async () => {
        try {
          await cancelIfUnfinished(uuid);
          setCurrentState(States.SOCKET_EXPIRED);
        } catch (e) {
          errorToast(await extractBestErrorMessage(e));
        }
      }, 10 * 60 * 1000 /* 10 mins */);
    } else {
      cleanupTimeout(idleTimeout);
    }
    return () => {
      cleanupTimeout(idleTimeout);
    };
  }, [uuid, currentState, setCurrentState]);
}

export function useQrFetcher(uuid: string, currentState: States, setCurrentState: Function, setQr: Function, setErrorMsg: Function) {
  useEffect(() => {
    const fetchFunc = async () => {
      let qrData, hasFailed;
      try {
        qrData = await fetchQrData(uuid);
      } catch (e: any) {
        hasFailed = true;
        if (e.response.status === 404) {
          return setCurrentState(States.UUID_INVALID);
        }
        const msg = await extractBestErrorMessage(e);
        if (msg === 'API Error: Socket initialisation required') {
          // todo: refactor to make the extractBestErrorMessage function return all bits of data, including the
          // human message and the WHAM error code -- much nicer to compare wham error codes here and not a human message
          setCurrentState(States.PENDING_SOCKET_CREATION);
        } else {
          setQr(null);
          setErrorMsg(msg);
          setCurrentState(States.ERROR);
        }
      }
      if (qrData?.hasBeenScanned) {
        setCurrentState(States.COMPLETE);
        setQr(null);
      }
      if (qrData?.qr && !hasFailed) {
        setQr(qrData.qr);
      }
    };
    let refreshInterval: NodeJS.Timer | undefined;
    if (currentState === States.SOCKET_ACTIVE) {
      fetchFunc();
      refreshInterval = setInterval(fetchFunc, 5000);
    } else {
      setQr(null);
      cleanupInterval(refreshInterval);
    }
    return () => cleanupInterval(refreshInterval);
  }, [uuid, currentState, setCurrentState, setQr]);
};
