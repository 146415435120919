type HttpMethod = 'GET' | 'POST';
interface FetchOptions {
  method: 'GET' | 'POST',
  headers: {
    [key: string]: string;
  },
  body?: string; // Stringified json
}

const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export async function post(url: string, headers = {}, body?: Object) : Promise<Response> {
  return whamFetch(url, headers, 'POST', body);
}

export async function get(url: string, headers = {}) : Promise<Response> {
  return whamFetch(url, headers);
}

export async function whamFetch(url: string, headers = {}, method: HttpMethod = 'GET', body?: Object) : Promise<Response>  {
  const apiUrl = buildApiUrl(url);
  const params = new URLSearchParams(window.location.search);
  let fetchOptions: FetchOptions = {
    method,
    headers: {
      Authorization: `Bearer ${params.get('token') || ''}`,
      ...BASE_HEADERS,
      ...headers,
    },
  };
  if (body) {
    fetchOptions.body = JSON.stringify(body);
  }

  const fetchResult = await fetch(`${apiUrl}`, fetchOptions);
  if (fetchResult.status >= 400) {
    throw new ApiError(`API Error ${fetchResult.status}`, fetchResult);
  }
  return fetchResult;
}

export async function initialiseSocket(uuid: string) : Promise<any> {
  await post('api/sessions/start', {}, {
    uuid,
  });
};

export async function fetchQrData(uuid: string) : Promise<any> {
  const result = await get(`api/qr?uuid=${uuid}`);
  return await result.json();
};

export async function cancelIfUnfinished(uuid: string) : Promise<any> {
  await post('api/sessions/cancel-unfinished', {}, {
    uuid,
  });
};

export async function resendEmail(uuid: string) : Promise<any> {
  await post('api/qr/resend-email', {}, {
    uuid,
  });
};

function buildApiUrl(endPoint: string) {
  const apiHost = getHost();
  return `${apiHost}/${endPoint}`;
}

function getHost() {
  return (window as any).VS_SETTINGS.apiHost;
}

export class ApiError extends Error {
  readonly response: Response;

  constructor(text: string, response: Response) {
    super(text);
    this.response = response;
  }
}
