import React, { useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import { resendEmail } from '../serverApi';
import { errorToast } from '../Toasts';
import { States } from './lib/types';

// SOCKET_ACTIVE looks out of place as a loading state, but the reason being that there
// is potential for an overlap where the QR is not yet fetched, but the socket is ready
const LOADING_STATES = [States.PENDING, States.PENDING_SOCKET_CREATION, States.SOCKET_ACTIVE];
const COMPLETE_STATES = [States.COMPLETE, States.ALREADY_CAPTURING];
const POTENTIAL_URL_ISSUE_MESSAGES = ['Incorrect token provided', 'Missing matching token'];

interface StatefulFeedbackProps {
  state: States;
  setCurrentState: Function;
  uuid: string;
  errorMsg: string;
}

type ResendStatus = 'pending' | 'requesting' | 'requested';

export default function StatefulFeedback ({state, setCurrentState, uuid, errorMsg}: StatefulFeedbackProps) {
  const [resendStatus, setResendStatus] = useState<ResendStatus>('pending');

  if (LOADING_STATES.includes(state)) {
    return (
      <div>
        <h3>Preparing WhatsApp Session...</h3>
        <h1><LoadingSpinner /></h1>
      </div>
    );
  }

  if (COMPLETE_STATES.includes(state)) {
    return (
      <div>
        <p>Signed in successfully</p>
        <p>VoxSmart is connected to your WhatsApp account</p>
        <p>It is now safe to close this window.</p>
      </div>
    );
  }

  if (state === States.UUID_INVALID) {
    return (
      <div>
        <p className="error">This URL is invalid. Please find the <u>most recent</u> sign-in email sent to you by VoxSmart, and open that link.</p>
      </div>
    );
  }

  if (state === States.UUID_EXPIRED) {
    return (
      <div>
        <p className="error">This URL has expired. To continue with authentication please request a new email below.</p>
        <button disabled={resendStatus !== 'pending'} className="btn btn-primary" onClick={async () => {
          try {
            setResendStatus('requesting');
            await resendEmail(uuid);
            setResendStatus('requested');
          } catch (e) {
            setResendStatus('pending');
            errorToast('Failed to request email resend.');
          }
        }}>
          {resendStatus === 'pending' && <p>Resend Email</p>}
          {resendStatus === 'requesting' && <p><LoadingSpinner /> Resending</p>}
          {resendStatus === 'requested' && <p>Resend Requested</p>}
        </button>
      </div>
    );
  }
  
  if (state === States.SOCKET_EXPIRED) {
    return (
      <div>
        <p className="error">WhatsApp session expired from being idle too long.</p>
        <button className="btn btn-primary" onClick={() => setCurrentState(States.SOCKET_ACTIVE)}><p>Refresh Session</p></button>
      </div>
    );
  }


  if (state === States.ERROR) {
    return (
      <div>
        <p className="error">Error occurred!</p>
        {errorMsg && <p className="error">{errorMsg}</p> }
        {POTENTIAL_URL_ISSUE_MESSAGES.includes(errorMsg)
        && <p>It looks like the URL may be incorrect. Ensure you opened the link exactly as provided in your most recent email from VoxSmart.</p>}
        <p>Please try again and contact VoxSmart if the issue persists.</p>
      </div>
    );
  }

  return <p>Unknown state: {Object.values(States)[state]}</p>;
}
